import { MultipliersEntity } from '@Mesh/core/models/bonus/bonus-configuration';

export enum GoalType {
  NEW = 'new',
  EXISTED = 'existed',
}

export enum GoalIdType {
  val = 'val',
  top24SaleVolume = 'top24SaleVolume',
  top24Material = 'top24Material',
  orderAmount = 'orderAmount',
  driveList = 'driveList',
  amount = 'amount',
}

export enum GoalIdTypeLabels {
  val = 'VAL',
  top24SaleVolume = 'TOP24SALEVOLUME',
  top24Material = 'TOP24MATERIAL',
  orderAmount = 'ORDERAMOUNT',
  driveList = 'DRIVELIST',
  amount = 'AMOUNT',
}

export enum ScoringMechanicsType {
  BY_SECTOR = 'byProduct',
  BY_PRODUCT = 'bySector',
}

export enum CoefficientValueType {
  ITEM = 'item',
  PERCENT = 'percent',
  BONUS = 'bonus',
}

export enum TargetUnitType {
  ITEM = 'item',
  PRICE = 'price',
}

export enum BonusStrategy {
  FIXED_BONUS_STRATEGY = 'FIXED_BONUS_STRATEGY',
  DYNAMIC_BONUS_STRATEGY = 'DYNAMIC_BONUS_STRATEGY',
}

export enum CoefficientType {
  GOLD = 'GOLD',
  SILVER = 'SILVER',
  IRON = 'IRON',
}

export interface IMultipliers {
  id?: number;
  addressSegment: CoefficientType | string;
  percentageStep: number;
  multiplier: number;
}

export interface INewGoal {
  name: string;
  description: string;
  step: number;
  strategy: string;
  priorityId: number;
  goldBonus: number;
  silverBonus: number;
  ironBonus: number;
  external: boolean;
  material: boolean;
  money: boolean;
  image: string;
  addressSegmentBonuses: { id?: number; type: CoefficientType; value: number }[];
  multipliers: IMultipliers[];
  userBonusStrategyConfig: { userBonusStrategy: BonusStrategy; value: number };
  bonusConfigItems: { id?: number; materialId?: number; sectorId?: string; quant?: number }[];
  shortName: string;
  planId: number;
  categoryId: number;
  distributorId: number;
  userGoldBonus: number;
  userIronBonus: number;
  userSilverBonus: number;
  userMultipliers: IMultipliers[];
  userTypes: string[];
  maxBonusValue: number;
  useMaxBonusValue?: boolean;
}
